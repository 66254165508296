/*
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-01-22 15:38:54
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-31 11:29:59
 */
import http from "./axios_init";
export default {
  /**
   * 获取所有类型列表
   * @returns
   */
  get_types() {
    return http.get("seller/v1/note/type");
  },
  /**
   * 获取可用的类型列表
   * @returns
   */
  get_usable_types() {
    return http.get("seller/v1/note/type/usable");
  },
};
